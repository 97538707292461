import React from 'react'
import PropTypes from 'prop-types'

import './SectionHeaderRow.scss'

const SectionHeaderRow = ({
  headline,
  entryText,
  entypoClass,
  className,
  ...restProps
}) => {
  return (
    <div className="row justify-content-center">
      <div
        className={`col-lg-10 section-header text-center ${className}`}
        {...restProps}
      >
        <h2 className={`section-headline text-uppercase ${entypoClass}`}>
          {headline}
        </h2>
        {entryText ? <p className="mb-3">{entryText}</p> : null}
      </div>
    </div>
  )
}

SectionHeaderRow.propTypes = {
  headline: PropTypes.string,
  entryText: PropTypes.any,
  entypoClass: PropTypes.string,
  className: PropTypes.string,
}

SectionHeaderRow.defaultProps = {
  headline: '',
  entryText: '',
  entypoClass: '',
  className: null,
}

export default SectionHeaderRow
