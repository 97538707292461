import React from 'react'
import PropTypes from 'prop-types'

import './PageSection.scss'

const PageSection = ({ children, className, dark, ...restProps }) => {
  return (
    <section
      className={`${dark ? 'page-section-dark' : 'page-section'} ${className}`}
      {...restProps}
    >
      <div className="container">{children}</div>
    </section>
  )
}

PageSection.propTypes = {
  children: PropTypes.any,
  dark: PropTypes.bool,
  className: PropTypes.string,
}

PageSection.defaultProps = {
  children: null,
  dark: false,
  className: '',
}

export default PageSection
