import React, { useState } from 'react'
// import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'

// eslint-disable-next-line react/prop-types
function Impressum({ location }) {
  const [dataVisible, setDataVisible] = useState(false)

  return (
    <Layout location={location} showSlider={false}>
      <SEO keywords={[`gatsby`, `react`]} title="Impressum" />
      <PageSection id="messe">
        <SectionHeaderRow headline="Impressum" />
        <div className="row">
          <div className="col-12">
            {/* <div style={{marginBottom: '-220px', textAlign: 'right'}}><embed src="https://activate.reclay.de/ShowLogo200.html?clientId=112804" width={220} height={220} /></div> */}
            <h4>Herausgeber:</h4>
            <p>
              <a href="https://dl3.pushbulletusercontent.com/DZBTZ6GdkO9QnkPIXTtzCmdvaMrkBq92/Der_Ku_1772-300x136.jpg">
                <img
                  className="h-auto"
                  style={{ width: '100px' }}
                  src="https://dl3.pushbulletusercontent.com/DZBTZ6GdkO9QnkPIXTtzCmdvaMrkBq92/Der_Ku_1772-300x136.jpg"
                  alt="Der Ku"
                />
              </a>
            </p>
            <p>
              {dataVisible ? (
                <span>
                  Veilchenweg 16
                  <br />
                  44651 Herne
                  <br />
                </span>
              ) : (
                <span
                  className="c-pointer text-primary"
                  onClick={() => setDataVisible(true)}
                >
                  Adresse anzeigen
                  <br />
                </span>
              )}
              Telefon: 02325 79 79 72
              <br />
              Mobil: 0178 79 79 720
              <br />
              <span>eMail:&nbsp;</span>
              {dataVisible ? (
                <span>info (at) kuboshow.de</span>
              ) : (
                <span
                  className="c-pointer text-primary"
                  onClick={() => setDataVisible(true)}
                >
                  E-Mail-Adresse anzeigen
                </span>
              )}
              <br />
              <a
                title="facebook"
                href="https://www.facebook.com/Kuboshow-Kunstmesse-1642193969357580/timeline/?ref=hl"
                target="_blank"
                rel="noreferrer"
              >
                KUBOSHOW KUNSTMESSE on facebook
              </a>
            </p>
            <p>
              Veranstalterin der KUBOSHOW KUNSTMESSE
              <br />
              Der Ku UG (haftungsbeschränkt) Messen und Marketing
            </p>
            <p>
              Dieser Webauftritt enthält journalistisch-redaktionelle Beiträge.
              <br />
              Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:
              <br />
              Der Ku UG/ H. Wennrich (Anschrift wie oben)
            </p>
            <p>
              Alle hier gezeigten Fotos, Logos und Texte stammen von Der Ku UG
              bzw. liegen dem Unternehmen die entsprechenden
              Verwendungsfreigaben der Urheber vor. Die verwendete Karte wird
              mit der freundlichen Genehmigung des Fachbereichs Kataster der
              Stadt Herne verwendet.
            </p>
            <h4>Haftung für Inhalte</h4>
            <p>
              Die auf den Web-Seiten abrufbaren Beiträge dienen nur der
              allgemeinen Information und nicht der Beratung in konkreten
              Fällen. Wir sind bemüht, für die Richtigkeit und Aktualität aller
              auf der Website enthaltenen Informationen und Daten gemäß § 7
              Abs.1 TMG zu sorgen. Für die Korrektheit, die Vollständigkeit, die
              Aktualität oder Qualität der bereitgestellten Informationen und
              Daten wird jedoch keine Gewähr nach § 8 bis 10 TMG übernommen. Die
              Haftung für den Inhalt der abrufbaren Informationen wird
              ausgeschlossen, soweit es sich nicht um vorsätzliche oder grob
              fahrlässige Falschinformation handelt. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <h4>Haftung für Links</h4>
            <p>
              Wir sind für den Inhalt von Webseiten, die über einen Hyperlink
              erreicht werden, nicht verantwortlich. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich. Wir machen uns die Inhalte dieser Internetseiten
              ausdrücklich nicht zu eigen und können deshalb für die inhaltliche
              Korrektheit, Vollständigkeit und Verfügbarkeit keine Gewähr
              leisten. Wir haben bei der erstmaligen Verknüpfung zwar den
              fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche
              zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst
              wird. Wir sind aber nicht dazu verpflichtet, die Inhalte, auf die
              wir unserem Angebot verweisen, ständig auf Veränderungen zu
              überprüfen, die eine Verantwortlichkeit neu begründen könnten.
              Erst wenn wir feststellen oder von anderen darauf hingewiesen
              werden, dass ein konkretes Angebot, zu dem wir einen Link
              bereitgestellt haben, eine zivil- oder strafrechtliche
              Verantwortlichkeit auslöst, werden wir den Verweis auf dieses
              Angebot aufheben, soweit uns dies technisch möglich und zumutbar
              ist.
            </p>
            <h4>Urheberrecht</h4>
            <div id="datenschutz" />
            <p>
              Die durch den Betreiber dieser Seite erstellten Inhalte und Werke
              auf diesen Webseiten unterliegen dem deutschen Urheberrecht.
              Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Kopien von diesen Seiten sind nur für den privaten Bereich
              gestattet, nicht jedoch für kommerzielle Zwecke. Für sämtliche
              Fotos, Texte, Lebensläufe der Künstler liegen die Urheberrechte
              bzw. die Nutzungsrechte bei dem Betreiber der Webseite.
            </p>

            <h2 className="mt-4">Datenschutzerklärung</h2>
            <p>
              Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt)
              werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke
              der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
              Internetauftritts, inklusive seiner Inhalte und der dort
              angebotenen Leistungen, verarbeitet.
            </p>
            <p>
              Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
              Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt),
              gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten, wie das Erheben, das
              Erfassen, die Organisation, das Ordnen, die Speicherung, die
              Anpassung oder Veränderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
              eine andere Form der Bereitstellung, den Abgleich oder die
              Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            </p>
            <p>
              Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
              insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage
              der Verarbeitung personenbezogener Daten, soweit wir entweder
              allein oder gemeinsam mit anderen über die Zwecke und Mittel der
              Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend
              über die von uns zu Optimierungszwecken sowie zur Steigerung der
              Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
              Dritte Daten in wiederum eigener Verantwortung verarbeiten.
            </p>
            <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
            <p>
              I. Informationen über uns als Verantwortliche
              <br />
              II. Rechte der Nutzer und Betroffenen
              <br />
              III. Informationen zur Datenverarbeitung
            </p>
            <h3>I. Informationen über uns als Verantwortliche</h3>
            <p>
              Verantwortlicher Anbieter dieses Internetauftritts im
              datenschutzrechtlichen Sinne ist:
            </p>
            <p>
              {dataVisible ? (
                <span>
                  Veilchenweg 16
                  <br />
                  44651 Herne
                  <br />
                </span>
              ) : (
                <span
                  className="c-pointer text-primary"
                  onClick={() => setDataVisible(true)}
                >
                  Adresse anzeigen
                  <br />
                </span>
              )}
              Telefon: 02325 79 79 72
              <br />
              Mobil: 0178 79 79 720
              <br />
              <span>eMail:&nbsp;</span>
              {dataVisible ? (
                <span>info (at) kuboshow.de</span>
              ) : (
                <span
                  className="c-pointer text-primary"
                  onClick={() => setDataVisible(true)}
                >
                  E-Mail-Adresse anzeigen
                </span>
              )}
            </p>
            <h3>II. Rechte der Nutzer und Betroffenen</h3>
            <p>
              Mit Blick auf die nachfolgend noch näher beschriebene
              Datenverarbeitung haben die Nutzer und Betroffenen das Recht
            </p>
            <ul>
              <li>
                auf Bestätigung, ob sie betreffende Daten verarbeitet werden,
                auf Auskunft über die verarbeiteten Daten, auf weitere
                Informationen über die Datenverarbeitung sowie auf Kopien der
                Daten (vgl. auch Art. 15 DSGVO);
              </li>
              <li>
                auf Berichtigung oder Vervollständigung unrichtiger bzw.
                unvollständiger Daten (vgl. auch Art. 16 DSGVO);
              </li>
              <li>
                auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch
                Art. 17 DSGVO), oder, alternativ, soweit eine weitere
                Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf
                Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
              </li>
              <li>
                auf Erhalt der sie betreffenden und von ihnen bereitgestellten
                Daten und auf Übermittlung dieser Daten an andere
                Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
              </li>
              <li>
                auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der
                Ansicht sind, dass die sie betreffenden Daten durch den Anbieter
                unter Verstoß gegen datenschutzrechtliche Bestimmungen
                verarbeitet werden (vgl. auch Art. 77 DSGVO).
              </li>
            </ul>
            <p>
              Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger,
              denen gegenüber Daten durch den Anbieter offengelegt worden sind,
              über jedwede Berichtigung oder Löschung von Daten oder die
              Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17
              Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung
              besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit
              einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet
              dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
            </p>
            <p>
              <strong>
                Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO
                das Recht auf Widerspruch gegen die künftige Verarbeitung der
                sie betreffenden Daten, sofern die Daten durch den Anbieter nach
                Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden.
                Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum
                Zwecke der Direktwerbung statthaft.
              </strong>
            </p>
            <h3>III. Informationen zur Datenverarbeitung</h3>
            <p>
              Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten
              werden gelöscht oder gesperrt, sobald der Zweck der Speicherung
              entfällt, der Löschung der Daten keine gesetzlichen
              Aufbewahrungspflichten entgegenstehen und nachfolgend keine
              anderslautenden Angaben zu einzelnen Verarbeitungsverfahren
              gemacht werden.
            </p>

            <h4>Serverdaten</h4>
            <p>
              Aus technischen Gründen, insbesondere zur Gewährleistung eines
              sicheren und stabilen Internetauftritts, werden Daten durch Ihren
              Internet-Browser an uns bzw. an unseren Webspace-Provider
              übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und
              Version Ihres Internetbrowsers, das Betriebssystem, die Website,
              von der aus Sie auf unseren Internetauftritt gewechselt haben
              (Referrer URL), die Website(s) unseres Internetauftritts, die Sie
              besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die
              IP-Adresse des Internetanschlusses, von dem aus die Nutzung
              unseres Internetauftritts erfolgt, erhoben.
            </p>
            <p>
              Diese so erhobenen Daten werden vorrübergehend gespeichert, dies
              jedoch nicht gemeinsam mit anderen Daten von Ihnen.
            </p>
            <p>
              Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs.
              1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
              Verbesserung, Stabilität, Funktionalität und Sicherheit unseres
              Internetauftritts.
            </p>
            <p>
              Die Daten werden spätestens nach sieben Tage wieder gelöscht,
              soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich
              ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines
              Vorfalls ganz oder teilweise von der Löschung ausgenommen.
            </p>

            <h4>Cookies</h4>
            <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
            <p>
              Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies
              sind kleine Textdateien oder andere Speichertechnologien, die
              durch den von Ihnen eingesetzten Internet-Browser auf Ihrem
              Endgerät ablegt und gespeichert werden. Durch diese Cookies werden
              im individuellen Umfang bestimmte Informationen von Ihnen, wie
              beispielsweise Ihre Browser- oder Standortdaten oder Ihre
              IP-Adresse, verarbeitet. &nbsp;
            </p>
            <p>
              Durch diese Verarbeitung wird unser Internetauftritt
              benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung
              bspw. die Wiedergabe unseres Internetauftritts in
              unterschiedlichen Sprachen oder das Angebot einer
              Warenkorbfunktion ermöglicht.
            </p>
            <p>
              Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.)
              DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder
              Vertragsabwicklung verarbeitet werden.
            </p>
            <p>
              Falls die Verarbeitung nicht der Vertragsanbahnung oder
              Vertragsabwicklung dient, liegt unser berechtigtes Interesse in
              der Verbesserung der Funktionalität unseres Internetauftritts.
              Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
            </p>
            <p>
              Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies
              gelöscht.
            </p>
            <h5>b) Drittanbieter-Cookies</h5>
            <p>
              Gegebenenfalls werden mit unserem Internetauftritt auch Cookies
              von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der
              Analyse oder der Funktionalitäten unseres Internetauftritts
              zusammenarbeiten, verwendet.
            </p>
            <p>
              Die Einzelheiten hierzu, insbesondere zu den Zwecken und den
              Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies,
              entnehmen Sie bitte den nachfolgenden Informationen.
            </p>
            <h5>c) Beseitigungsmöglichkeit</h5>
            <p>
              Sie können die Installation der Cookies durch eine Einstellung
              Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls
              können Sie bereits gespeicherte Cookies jederzeit löschen. Die
              hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von
              Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen
              Sie daher bitte die Hilfefunktion oder Dokumentation Ihres
              Internet-Browsers oder wenden sich an dessen Hersteller bzw.
              Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings
              nicht über die Einstellungen des Browsers unterbunden werden.
              Stattdessen müssen Sie insoweit die Einstellung Ihres
              Flash-Players ändern. Auch die hierfür erforderlichen Schritte und
              Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei
              Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder
              Dokumentation Ihres Flash-Players oder wenden sich an den
              Hersteller bzw. Benutzer-Support.
            </p>
            <p>
              Sollten Sie die Installation der Cookies verhindern oder
              einschränken, kann dies allerdings dazu führen, dass nicht
              sämtliche Funktionen unseres Internetauftritts vollumfänglich
              nutzbar sind.{' '}
            </p>

            <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>
            <p>
              Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt
              treten, werden die dabei von Ihnen angegebenen Daten zur
              Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur
              Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne
              deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls
              eingeschränkt beantworten.
            </p>
            <p>
              Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b)
              DSGVO.
            </p>
            <p>
              Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend
              beantwortet worden ist und der Löschung keine gesetzlichen
              Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich
              etwaig anschließenden Vertragsabwicklung.
            </p>

            <h3>Verlinkung Social-Media über Grafik oder Textlink</h3>
            <p>
              Wir bewerben auf unserer Webseite auch Präsenzen auf den
              nachstehend aufgeführten sozialen Netzwerken. Die Einbindung
              erfolgt dabei über eine verlinkte Grafik des jeweiligen Netzwerks.
              Durch den Einsatz dieser verlinkten Grafik wird verhindert, dass
              es bei dem Aufruf einer Website, die über eine
              Social-Media-Bewerbung verfügt, automatisch zu einem
              Verbindungsaufbau zum jeweiligen Server des sozialen Netzwerks
              kommt, um eine Grafik des jeweiligen Netzwerkes selbst
              darzustellen. Erst durch einen Klick auf die entsprechende Grafik
              wird der Nutzer zu dem Dienst des jeweiligen sozialen Netzwerks
              weitergeleitet.
            </p>
            <p>
              Nach der Weiterleitung des Nutzers werden durch das jeweilige
              Netzwerk Informationen über den Nutzer erfasst. Es kann hierbei
              nicht ausgeschlossen werden, dass eine Verarbeitung der so
              erhobenen&nbsp; Daten in den USA stattfindet.
            </p>
            <p>
              Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und
              besuchte Seite. Ist der Nutzer währenddessen in seinem
              Benutzerkonto des jeweiligen Netzwerks eingeloggt, kann der
              Netzwerk-Betreiber ggf. die gesammelten Informationen des
              konkreten Besuchs des Nutzers dem persönlichen Account des Nutzers
              zuordnen. Interagiert der Nutzer über einen „Teilen“-Button des
              jeweiligen Netzwerks, können diese Informationen in dem
              persönlichen Benutzerkonto des Nutzers gespeichert und ggf.
              veröffentlicht werden. Will der Nutzer verhindern, dass die
              gesammelten Informationen unmittelbar seinem Benutzerkonto
              zugeordnet werden, muss er sich vor dem Anklicken der Grafik
              ausloggen. Zudem besteht die Möglichkeit, das jeweilige
              Benutzerkonto entsprechend zu konfigurieren.
            </p>
            <p>
              Folgende soziale Netzwerke werden in unsere Seite durch Verlinkung
              eingebunden:
            </p>

            <h5>facebook / Instagram</h5>
            <p>
              Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland,
              ein Tochterunternehmen der Facebook Inc., 1601 S. California Ave.,
              Palo Alto, CA 94304, USA.
            </p>
            <p>
              Datenschutzerklärung:{' '}
              <a
                href="https://www.facebook.com/policy.php"
                target="_blank"
                rel="noreferrer"
              >
                https://www.facebook.com/policy.php
              </a>
            </p>
            <p>
              Zertifizierung EU-US-Datenschutz („EU-US Privacy Shield“){' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                target="_blank"
                rel="noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
              </a>
            </p>
            <h5>Google-Maps</h5>
            <p>
              In unserem Internetauftritt setzen wir Google Maps zur Darstellung
              unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung
              ein. Es handelt sich hierbei um einen Dienst der Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              nachfolgend nur „Google“ genannt.
            </p>
            <p>
              Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US
              Privacy Shield“)
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              garantiert Google, dass die Datenschutzvorgaben der EU auch bei
              der Verarbeitung von Daten in den USA eingehalten werden.
            </p>
            <p>
              Um die Darstellung bestimmter Schriften in unserem
              Internetauftritt zu ermöglichen, wird bei Aufruf unseres
              Internetauftritts eine Verbindung zu dem Google-Server in den USA
              aufgebaut.
            </p>
            <p>
              Sofern Sie die in unseren Internetauftritt eingebundene Komponente
              Google Maps aufrufen, speichert Google über Ihren Internet-Browser
              ein Cookie auf Ihrem Endgerät. Um unseren Standort anzuzeigen und
              eine Anfahrtsbeschreibung zu erstellen, werden Ihre
              Nutzereinstellungen und -daten verarbeitet. Hierbei können wir
              nicht ausschließen, dass Google Server in den USA einsetzt.
            </p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
              berechtigtes Interesse liegt in der Optimierung der Funktionalität
              unseres Internetauftritts.
            </p>
            <p>
              Durch die so hergestellte Verbindung zu Google kann Google
              ermitteln, von welcher Website Ihre Anfrage gesendet worden ist
              und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln
              ist.
            </p>
            <p>
              Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben
              Sie die Möglichkeit, die Installation der Cookies durch die
              entsprechenden Einstellungen in Ihrem Internet-Browser zu
              verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem
              Punkt „Cookies“.
            </p>
            <p>
              Zudem erfolgt die Nutzung von Google Maps sowie der über Google
              Maps erlangten Informationen nach den{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.google.de/accounts/TOS"
              >
                Google-Nutzungsbedingungen
              </a>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/terms?gl=DE&amp;hl=de"
              >
                https://policies.google.com/terms?gl=DE&amp;hl=de
              </a>{' '}
              und den{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/intl/de_de/help/terms_maps.html"
              >
                Geschäftsbedingungen für Google Maps
              </a>{' '}
              https://www.google.com/intl/de_de/help/terms_maps.html.
            </p>
            <p>
              Überdies bietet Google unter{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://adssettings.google.com/authenticated"
              >
                https://adssettings.google.com/authenticated
              </a>{' '}
              sowie{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy"
              >
                https://policies.google.com/privacy
              </a>{' '}
              weitergehende Informationen an.
            </p>

            <h5>CloudFlare</h5>
            <p>
              Zur Absicherung unseres Internetauftritts sowie zur Optimierung
              der Ladezeiten setzen wir den Dienst CloudFlare als sog. CDN
              (Content-Delivery-Network) ein. Es handelt sich hierbei um einen
              Dienst der Cloudflare Inc., 101 Townsend Street, San Francisco,
              California 94107, USA, nachfolgend nur „CloudFlare“ genannt.
            </p>
            <p>
              Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US
              Privacy Shield“)
            </p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active"
                target="_blank"
                rel="noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active
              </a>
            </p>
            <p>
              garantiert CloudFlare, dass die Datenschutzvorgaben der EU auch
              bei der Verarbeitung von Daten in den USA eingehalten werden.
            </p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
              berechtigtes Interesse liegt in dem sicheren Betrieb unseres
              Internetauftritts sowie in dessen Optimierung.
            </p>
            <p>
              Sofern Sie unseren Internetauftritt aufrufen, werden Ihre Anfragen
              über den Server von CloudFlare geleitet. Hierbei werden
              statistische Zugriffsdaten über den Besuch unseres
              Internetauftritts erhoben und durch CloudFlare ein Cookie über
              Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Zu den
              Zugriffsdaten zählen
            </p>
            <ul>
              <li> Ihre IP-Adresse,</li>
              <li>
                die von Ihnen aufgerufene(n) Internetseite(n) unseres
                Internetauftritts,
              </li>
              <li>
                Typ und Version des von Ihnen genutzten Internet - Browsers,
              </li>
              <li> das von Ihnen genutzte Betriebssystem,</li>
              <li>
                die Internetseite, von der sie auf unseren Internetauftritt
                gewechselt haben (Referrer- URL),
              </li>
              <li> Ihre Verweildauer auf unserem Internetauftritt und&nbsp;</li>
              <li> die Häufigkeit des Aufrufs unserer Internetseiten.</li>
            </ul>
            <p>
              Die Daten werden durch CloudFlare zum Zwecke statistischer
              Auswertungen der Zugriffe sowie zur Sicherheit und Optimierung des
              Angebots verwendet.
            </p>
            <p>
              Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben
              Sie die Möglichkeit, die Installation der Cookies durch die
              entsprechenden Einstellungen in Ihrem Internet-Browser zu
              verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem
              Punkt „Cookies“.
            </p>
            <p>
              CloudFlare bietet unter{' '}
              <a
                href="https://www.cloudflare.com/privacypolicy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.cloudflare.com/privacypolicy/
              </a>{' '}
              weitere Informationen zur Erhebung und Nutzung der Daten sowie zu
              Ihren Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre an.
            </p>
            <h5>Netlify</h5>
            <p>
              Wir verwenden die Dienste von Netlify Inc. (&quot;Netlify&quot;).
              Indem Sie uns personenbezogene Daten zur Verfügung stellen,
              erklären Sie sichfreiwillig mit der Erhebung, Verwendung und
              Offenlegung solcher personenbezogenen Daten an Netlify
              einverstanden. Netlify gibt Ihre persönlichen Daten nur an Dritte
              weiter, sofern dies für die Bereitstellung eines bestimmten
              Dienstes erforderlich und im Einklang mit unseren
              Datenschutzbestimmungen ist. Ihre persönlichen Daten werden von
              Netlify verwendet, um Dienste für Sie bereitzustellen und die
              Qualität der Webseite und der Dienste kontinuierlich zu
              verbessern, die Website und Dienste effektiver nutzen zu können,
              um Serviceniveaus zu bewerten sowie Verkehrsmuster zu überwachen
              und die Beliebtheit verschiedener Serviceoptionen zu messen.
              Netlify verwendet Cookies, um die Beliebtheit von Serviceangeboten
              zu messen. Netlify wird diese Informationen nutzen, um
              sicherzustellen, dass Besucher stets dieselbe Website sehen, wenn
              sie zur entsprechenden Web-URL zurückkehren. Ferner wird die
              Anzahl der klickenden Besucher auf unserer Website erhoben.
              Weiterführende Informationen zu der Datenschutzrichtlinie von
              Netlify finden Sie unter{' '}
              <a
                href="https://www.netlify.com/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.netlify.com/privacy/.
              </a>
            </p>
            <h5>Google Firebase</h5>
            <p>
              Unsere Website nutzt Technologie von Google Firebase (Google Inc.,
              1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
              &quot;Google&quot;). Firebase ist Teil der Google Cloud Platform
              und bietet für Entwickler zahlreiche Dienste an. Eine Auflistung
              dazu finden Sie hier: https://firebase.google.com/terms/. Einige
              Firebase Dienste verarbeiten personenbezogene Daten. In den
              meisten Fällen beschränken sich die personenbezogene Daten auf
              sogenannte &quot;Instance IDs&quot;, die mit einem Zeitstempel
              versehen sind. Diese von Firebase vergebenen &quot;Instance
              IDs&quot; sind einzigartig und erlauben damit das Verknüpfen von
              unterschiedlichen Geschehnissen oder Abläufen. Diese Daten stellen
              für uns weder persönlich identifizierbaren Informationen dar noch
              unternehmen wir Anstrengungen diese nachträglich zu
              personalisieren. Wir verarbeiten diese zusammengefassten Daten
              NICHT zur Analyse und Optimierung des Nutzungsverhaltens.
            </p>
            <p>Folgende Firebase Dienste werden von der Website genutzt:</p>
            <ul>
              <li>
                Firebase Cloud Functions ist ein Webhosting und Backend Dienst,
                bereitgestellt von Google Inc.
              </li>
              <li>
                Firebase Cloud Storage ist ein Webhosting Dienst, bereitgestellt
                von Google Inc.
              </li>
              <li>
                Firebase Cloud Firestore ist eine Datenbank und Backend Dienst,
                bereitgestellt von Google Inc.
              </li>
            </ul>
            <p>
              Weitere Informationen zum Datenschutz und zur Sicherheit von
              Firebase finden Sie hier:{' '}
              <a
                rel="noreferrer"
                href="https://firebase.google.com/support/privacy/"
                target="_blank"
              >
                https://firebase.google.com/support/privacy/
              </a>
              <br />
              Wir nutzen nach Möglichkeit Server mit einem Standort innerhalb
              der EU. Es ist aber nicht auszuschließen, dass Daten auch in die
              USA übertragen werden. Google ist dem EU-US Privacy-Shield
              beigetreten, einem Datenschutzabkommen zwischen der EU und den
              USA. Nähere Informationen zu Google Firebase und Datenschutz
              finden sich unter{' '}
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com/policies/privacy/
              </a>{' '}
              sowie unter{' '}
              <a
                href="https://firebase.google.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://firebase.google.com/
              </a>
            </p>
            <p className="mt-5">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
              >
                Muster-Datenschutzerklärung
              </a>{' '}
              der{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
              >
                Anwaltskanzlei Weiß &amp; Partner
              </a>
            </p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Impressum
